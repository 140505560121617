import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import logo from '../images/driver-logo-green-white.png';

const PageFooter = () => {
	const { t } = useTranslation();

	return (
		<footer className="driver-footer">
			<div>
				<img
					src={logo}
					width="150"
					alt={t('common.driver-technologies')}
				/>
			</div>

			<section>
				<div>
					<div>
						<h3>{t('footer.contact')}</h3>
						<ul>
							<li>
								<a href="mailto:info@drivertechnologies.com">info@drivertechnologies.com</a>
							</li>
						</ul>
					</div>
				</div>

				<div>
					<div>
						<h3>{t('footer.business')}</h3>
						<ul>
							<li>
								<Link to="/pricing#fleet-owners">{t('footer.fleet-owners')}</Link>
							</li>
							<li>
								<Link to="/pricing#product-integration">{t('footer.product-integration')}</Link>
							</li>
						</ul>
					</div>

					<div>
						<h3>{t('footer.support')}</h3>
						<ul>
							<li>
								<Link to="/careers">{t('navigation.careers') }</Link>
								<label className="semibold">We&#39;re Hiring!</label>
							</li>
							<li>
								<a href="https://trydriver.zendesk.com/hc/en-us" target="_blank" rel="noreferrer">
									{t('footer.help-center')}
								</a>
							</li>
							<li>
								<Link to="/terms-and-conditions">{t('footer.terms-and-conditions')}</Link>
							</li>
							<li>
								<Link to="/terms-and-conditions#privacy-policy">{t('footer.privacy-policy')}</Link>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</footer>
	);
};

export default PageFooter;
